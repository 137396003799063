import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Avatar from "@mui/material/Avatar";
import { LoadingButton } from "@mui/lab";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import SpaceBar from "@mui/icons-material/SpaceBar";
import ContentCopy from "@mui/icons-material/ContentCopy";
import ThumbsUpDownOutlinedIcon from "@mui/icons-material/ThumbsUpDownOutlined";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import useSecurityStore from "../../state/useSecurityStore";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import { apiFetch } from "./../../utils/api";
import { HighlightWithinTextarea } from "react-highlight-within-textarea";

interface CopyButtonProps {
  textToCopy: string;
}
interface SuggestionDialogProps {
  fixedText: string;
  setOpen: (open: boolean) => void;
  initialText: string;
  onSuccess: (suggestedText: string) => void;
  open: boolean;
}

function CopyButton(props: CopyButtonProps) {
  const [showCopyConfirm, setShowCopyConfirm] = useState(false);
  const { t } = useTranslation();

  const copyText = () => {
    try {
      navigator.clipboard.writeText(props.textToCopy);
      setShowCopyConfirm(true);
    } catch (error) {
      console.error("Failed to copy text: ", error);
    }
  };

  return (
    <>
      <Tooltip title="Copy">
        <IconButton onClick={copyText}>
          <ContentCopy />
        </IconButton>
      </Tooltip>
      <Snackbar
        open={showCopyConfirm}
        autoHideDuration={6000}
        onClose={() => setShowCopyConfirm(false)}
        message={t("text-copied-to-clipboard")}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
    </>
  );
}

function SuggestionDialog(props: SuggestionDialogProps) {
  const { t } = useTranslation();

  const [loadingSuggestion, setLoadingSuggestion] = useState(false);
  const [openSuggestionConfirm, setOpenSuggestionConfirm] = useState(false);
  const [suggestedText, setSuggestedText] = useState(props.fixedText);

  useEffect(() => {
    setSuggestedText(props.fixedText);
  }, [props.fixedText]);

  const closeDialog = () => {
    props.setOpen(false);
  };

  const sendSuggestion = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoadingSuggestion(true);
    const response = await apiFetch("spelling/create-suggestion", {
      method: "POST",
      body: JSON.stringify({ text: suggestedText }),
      headers: { "Content-Type": "application/json" },
    });

    if (response.ok) {
      props.setOpen(false);
      props.onSuccess(suggestedText);
      setLoadingSuggestion(false);
      setOpenSuggestionConfirm(true);
    } else {
      setLoadingSuggestion(false);
    }

    // axios.post(`${API_ENDPOINT}/create-suggestion`, {
    //   initial: props.initialText,
    //   corrected: props.fixedText,
    //   suggestion: suggestedText,
    // }).then(response => {
    // props.setOpen(false);
    // props.onSuccess(suggestedText);
    // setLoadingSuggestion(false);
    // setOpenSuggestionConfirm(true);
    //   }).catch((error) => {
    //     setLoadingSuggestion(false);
    //     window.alert(error);
    //   });
  };

  return (
    <>
      <Dialog open={props.open} onClose={closeDialog}>
        <DialogTitle>{t("edit-spelling")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t(
              "your-contribution-will-improve-the-quality-of-the-spell-checker"
            )}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="suggestion"
            fullWidth
            variant="standard"
            multiline
            value={suggestedText}
            onChange={(e) => setSuggestedText(e.target.value)}
            spellCheck={false}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <LoadingButton onClick={sendSuggestion} loading={loadingSuggestion}>
            {t("send")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSuggestionConfirm}
        autoHideDuration={6000}
        onClose={() => setOpenSuggestionConfirm(false)}
        message={t("thank-you-for-the-spelling-suggestion")}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
    </>
  );
}

export default function SpellCorrection() {
  const { t } = useTranslation();

  const [text, setText] = useState("");
  const [fixedText, setFixedText] = useState("");
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const user = useSecurityStore((state) => state.user);

  const spellCorrect = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    const response = await apiFetch("spelling", {
      method: "POST",
      body: JSON.stringify({ text }),
      headers: { "Content-Type": "application/json" },
    });

    if (response.ok) {
      const result = await response.json();
      const taskId = result.task_id;

      let taskResponse;
      let retryCount = 0;
      const maxRetries = 70;
      while (retryCount < maxRetries) {
        const response = await apiFetch(`spelling/${taskId}`);
        taskResponse = await response.json();
        if (taskResponse.status === "SUCCESS") {
          break;
        }
        retryCount++;
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }

      const taskResult = await taskResponse.result;
      setLoading(false);
      setFixedText(taskResult);
    }
  };

  return (
    <Container component="main" maxWidth="sm" sx={{ mb: 10 }}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 5,
          marginBottom: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <SpaceBar />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("spell-checker")}
        </Typography>
      </Box>
      <Box>
        <Box sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="ban-text"
            label={t("text-to-correct")}
            name="ban-text"
            minRows="3"
            onChange={(e) => setText(e.target.value)}
            spellCheck={false}
            value={text}
            multiline
            autoFocus
          />
          <LoadingButton
            onClick={spellCorrect}
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 3 }}
            loading={loading}
          >
            {t("fix-spelling")}
          </LoadingButton>
          <Grid container>
            {fixedText && (
              <HighlightWithinTextarea
                value={fixedText}
                highlight={[
                  fixedText.split(" ").filter((word) => !text.includes(word)),
                ]}
                textDirectionality="RTL"
              />
            )}
          </Grid>
        </Box>
      </Box>
      {fixedText && (
        <Grid container direction="row" justifyContent="flex-end">
          {(user?.email === "karwan.khalid@rudaw.net" ||
            user?.email === "rodan@rudaw.net" ||
            user?.email === "ali.zalmi@rudaw.net" ||
            user?.email === "brwa.kyani@rudaw.net" ||
            user?.email === "hejar@rudaw.net") && (
            <>
              <Tooltip title={t("edit-spelling")}>
                <IconButton onClick={() => setOpenDialog(true)}>
                  <ThumbsUpDownOutlinedIcon />
                </IconButton>
              </Tooltip>
            </>
          )}

          <CopyButton textToCopy={fixedText} />
        </Grid>
      )}

      <SuggestionDialog
        open={openDialog}
        setOpen={setOpenDialog}
        initialText={text}
        fixedText={fixedText}
        onSuccess={(savedText) => setFixedText(savedText)}
      />
    </Container>
  );
}
