import { useTranslation } from "react-i18next";
import LangIcon from "./LangIcon";
import { supportedLngs } from "./config";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export default function LocaleSwitcher() {
  const { i18n } = useTranslation();

  return (
    <div className="locale-switcher">
      <Select
        value={i18n.resolvedLanguage}
        onChange={(e) => i18n.changeLanguage(e.target.value)}
      >
        {Object.entries(supportedLngs).map(([code, name]) => (
          <MenuItem value={code} key={code}>
            {" "}
            {name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}
