import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./i18n/config.ts";
import { Commet } from "react-loading-indicators";
import * as serviceWorkerRegistration from './serviceWorkerResgistration';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <React.Suspense
      fallback={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Commet color="#FF5733" size="medium" text="" textColor="" />
        </div>
      }
    >
      <App />
    </React.Suspense>
  </React.StrictMode>
);
serviceWorkerRegistration.register();
