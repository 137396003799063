import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import useCollectionQuery from "../hooks/useCollectionQuery";
import { apiFetch } from "../utils/api";
import { downloadFromUrl } from "../utils/download";
import { getTempToken } from "../utils/security";
import { Recording } from "./Recording";

export const LANGUAGES = [
  { label: "Central Kurdish", id: "ckb" },
  { label: "Northern Kurdish", id: "ku" },
  { label: "English", id: "en" },
  { label: "Chinese", id: "zh" },
  { label: "German", id: "de" },
  { label: "Spanish", id: "es" },
  { label: "Russian", id: "ru" },
  { label: "Korean", id: "ko" },
  { label: "French", id: "fr" },
  { label: "Japanese", id: "ja" },
  { label: "Portuguese", id: "pt" },
  { label: "Turkish", id: "tr" },
  { label: "Polish", id: "pl" },
  { label: "Catalan", id: "ca" },
  { label: "Dutch", id: "nl" },
  { label: "Arabic", id: "ar" },
  { label: "Swedish", id: "sv" },
  { label: "Italian", id: "it" },
  { label: "Indonesian", id: "id" },
  { label: "Hindi", id: "hi" },
  { label: "Finnish", id: "fi" },
  { label: "Vietnamese", id: "vi" },
  { label: "Hebrew", id: "he" },
  { label: "Ukrainian", id: "uk" },
  { label: "Greek", id: "el" },
  { label: "Malay", id: "ms" },
  { label: "Czech", id: "cs" },
  { label: "Romanian", id: "ro" },
  { label: "Danish", id: "da" },
  { label: "Hungarian", id: "hu" },
  { label: "Tamil", id: "ta" },
  { label: "Norwegian", id: "no" },
  { label: "Thai", id: "th" },
  { label: "Urdu", id: "ur" },
  { label: "Croatian", id: "hr" },
  { label: "Bulgarian", id: "bg" },
  { label: "Lithuanian", id: "lt" },
  { label: "Latin", id: "la" },
  { label: "Maori", id: "mi" },
  { label: "Malayalam", id: "ml" },
  { label: "Welsh", id: "cy" },
  { label: "Slovak", id: "sk" },
  { label: "Telugu", id: "te" },
  { label: "Persian", id: "fa" },
  { label: "Latvian", id: "lv" },
  { label: "Bengali", id: "bn" },
  { label: "Serbian", id: "sr" },
  { label: "Azerbaijani", id: "az" },
  { label: "Slovenian", id: "sl" },
  { label: "Kannada", id: "kn" },
  { label: "Estonian", id: "et" },
  { label: "Macedonian", id: "mk" },
  { label: "Breton", id: "br" },
  { label: "Basque", id: "eu" },
  { label: "Icelandic", id: "is" },
  { label: "Armenian", id: "hy" },
  { label: "Nepali", id: "ne" },
  { label: "Mongolian", id: "mn" },
  { label: "Bosnian", id: "bs" },
  { label: "Kazakh", id: "kk" },
  { label: "Albanian", id: "sq" },
  { label: "Swahili", id: "sw" },
  { label: "Galician", id: "gl" },
  { label: "Marathi", id: "mr" },
  { label: "Punjabi", id: "pa" },
  { label: "Sinhala", id: "si" },
  { label: "Khmer", id: "km" },
  { label: "Shona", id: "sn" },
  { label: "Yoruba", id: "yo" },
  { label: "Somali", id: "so" },
  { label: "Afrikaans", id: "af" },
  { label: "Occitan", id: "oc" },
  { label: "Georgian", id: "ka" },
  { label: "Belarusian", id: "be" },
  { label: "Tajik", id: "tg" },
  { label: "Sindhi", id: "sd" },
  { label: "Gujarati", id: "gu" },
  { label: "Amharic", id: "am" },
  { label: "Yiddish", id: "yi" },
  { label: "Lao", id: "lo" },
  { label: "Uzbek", id: "uz" },
  { label: "Faroese", id: "fo" },
  { label: "Haitian Creole", id: "ht" },
  { label: "Pashto", id: "ps" },
  { label: "Turkmen", id: "tk" },
  { label: "Nynorsk", id: "nn" },
  { label: "Maltese", id: "mt" },
  { label: "Sanskrit", id: "sa" },
  { label: "Luxembourgish", id: "lb" },
  { label: "Myanmar", id: "my" },
  { label: "Tibetan", id: "bo" },
  { label: "Tagalog", id: "tl" },
  { label: "Malagasy", id: "mg" },
  { label: "Assamese", id: "as" },
  { label: "Tatar", id: "tt" },
  { label: "Hawaiian", id: "haw" },
  { label: "Lingala", id: "ln" },
  { label: "Hausa", id: "ha" },
  { label: "Bashkir", id: "ba" },
  { label: "Javanese", id: "jw" },
  { label: "Sundanese", id: "su" },
  { label: "Cantonese", id: "yue" },
];

export const SPEAKER_CONFIGS = [
  { label: "General", id: "GENERAL" },
  { label: "Meeting", id: "MEETING" },
  { label: "Telephone", id: "TELEPHONIC" },
];

export enum TranscriptionState {
  Pending = "PENDING",
  Running = "RUNNING",
  Success = "SUCCESS",
  Failure = "FAILURE",
}

export interface Transcription {
  id: number;
  recording: Recording;
  state: TranscriptionState;
  started_at: string | null;
  finished_at: string | null;
  identify_speakers: boolean;
  email_notification: boolean;
  improve_model: boolean;
  remove_noise: boolean;
  spell_correction: boolean;
  speaker_config: string | null;
  num_speakers: number | null;
  language: string | null;
}

export interface TranscriptionProgress {
  state: TranscriptionState;
  took: number | null;
}

export interface TranscriptionEdit {
  recording_id?: number;
  identify_speakers: boolean;
  email_notification: boolean;
  improve_model: boolean;
  remove_noise: boolean;
  spell_correction: boolean;
  language: string;
  speaker_config?: string;
  num_speakers?: number;
}

export const transcriptionDefaultValues: TranscriptionEdit = {
  identify_speakers: false,
  email_notification: false,
  improve_model: false,
  remove_noise: false,
  spell_correction: false,
  language: "",
  speaker_config: undefined,
  num_speakers: undefined,
};

export interface Transcript {
  text: string;
  language: string;
  num_speakers: number | null;
  speaker_segments: SpeakerSegment[] | null;
  segments: Segment[] | null;
}

export interface SpeakerSegment {
  text: string;
  speaker: string;
  start: number;
  end: number;
}

export interface Segment {
  text: string;
  start: number;
  end: number;
}
export const transcriptionsEqual = (a: Transcription, b: Transcription) =>
  a.id === b.id;

export const useTranscriptions = (
  recordingId: string | number | null = null
) => {
  return useCollectionQuery<Transcription>(
    `transcriptions${recordingId ? `?recording_id=${recordingId}` : ""}`,
    transcriptionsEqual,
    { queryKey: ["transcriptions", "collection", recordingId] }
  );
};

export const useDeleteTranscription = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
} = {}) => {
  return useMutation({
    mutationFn: async ({ id }: Transcription) => {
      const response = await apiFetch(`transcriptions/${id}`, {
        method: "DELETE",
      });

      if (!response.ok) {
        throw new Error(`Failed to delete transcription ${id}`);
      }
    },
    onSuccess,
    onError,
  });
};

export const useDeleteTranscriptions = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
} = {}) => {
  return useMutation({
    mutationFn: async (transcriptions: Transcription[]) => {
      await Promise.all(
        transcriptions.map(({ id }) =>
          apiFetch(`transcriptions/${id}`, { method: "DELETE" })
        )
      );
    },
    onSuccess,
    onError,
  });
};

export const useTerminateAllTranscriptions = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
} = {}) => {
  return useMutation({
    mutationFn: async () => {
      const response = await apiFetch("transcriptions/terminate_all");

      if (!response.ok) {
        throw new Error("Failed to terminate all transcriptions");
      }
    },
    onSuccess,
    onError,
  });
};

export const useTranscript = (
  transcription?: Transcription,
  enabled: boolean = true
) => {
  return useQuery<Transcript>({
    enabled: !!transcription && enabled,
    queryKey: ["transcriptions", "transcript", transcription?.id],
    queryFn: async () => {
      const response = await apiFetch(
        `transcriptions/${transcription?.id}/transcript`
      );

      if (response.ok) {
        return await response.json();
      }

      return null;
    },
  });
};

export const useTranscriptionProgress = ({
  transcription,
  refetchInterval,
  onChange,
}: {
  transcription: Transcription;
  refetchInterval: number;
  onChange?: (state: TranscriptionState) => void;
}) => {
  const shouldPoll = (state: TranscriptionState) => {
    return (
      state !== TranscriptionState.Success &&
      state !== TranscriptionState.Failure
    );
  };

  const getTook = (transcription: Transcription) => {
    if (transcription.started_at && transcription.finished_at) {
      const startedAt = new Date(transcription.started_at);
      const finishedAt = new Date(transcription.finished_at);
      const delta = finishedAt.getTime() - startedAt.getTime();
      return Math.floor(delta / 1000);
    }

    return null;
  };

  const [isPolling, setIsPolling] = useState<boolean>(
    shouldPoll(transcription.state)
  );

  const progress = useQuery<TranscriptionProgress>({
    queryFn: async () => {
      const response = await apiFetch(
        `transcriptions/${transcription.id}/progress`
      );

      if (!response.ok) {
        throw new Error("Error while fetching transcription progress.");
      }

      return await response.json();
    },
    queryKey: ["transcriptions", "state", transcription.id],
    initialData: {
      state: transcription.state,
      took: getTook(transcription),
    },
    refetchInterval,
    enabled: isPolling,
  });

  useEffect(() => {
    if (progress.isSuccess) {
      onChange?.(progress.data.state);
      setIsPolling(shouldPoll(progress.data.state));
    }
  }, [progress.data]);

  return progress.data;
};

export const downloadTranscript = async (
  transcription: Transcription,
  format: string
) => {
  const token = await getTempToken();
  const url = `api/transcriptions/${transcription.id}/transcript/export?format=${format}&token=${token}`;
  downloadFromUrl(url);
};

export const getSpeakerConfigLabel = (speakerConfig: string) => {
  const config = SPEAKER_CONFIGS.find(({ id }) => id == speakerConfig);
  return config?.label;
};
