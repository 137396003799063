import { useState } from "react";
import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import CodeIcon from "@mui/icons-material/Code";
import NotesIcon from "@mui/icons-material/Notes";
import { RiFileExcel2Fill } from "react-icons/ri";
import { BsFileSpreadsheetFill } from "react-icons/bs";
import { Transcription, downloadTranscript } from "../models/Transcription";
import { useTranslation } from "react-i18next";

export default function TranscriptDownloadMenu({
    transcription,
    disabled,
    asButton,
}: {
    transcription: Transcription;
    disabled?: boolean;
    asButton?: boolean;
}) {
    const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

    const { t } = useTranslation();

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElement(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorElement(null);
    }

    const downloadText = () => downloadTranscript(transcription, "txt")
    const downloadJSON = () => downloadTranscript(transcription, "json")
    const downloadSRT = () => downloadTranscript(transcription, "srt")
    const downloadVTT = () => downloadTranscript(transcription, "vtt");
    const downloadTSV = () => downloadTranscript(transcription, "tsv");

    return (
        <>
            {asButton ?
                <Button
                    color="primary"
                    title="Download"
                    disabled={disabled}
                    onClick={handleOpen}
                    startIcon={<DownloadIcon />}
                >
                    {t('download')}
                </Button> :
                <IconButton
                    color="primary"
                    title="Download"
                    disabled={disabled}
                    onClick={handleOpen}
                >
                    <DownloadIcon />
                </IconButton>
            }
            <Menu
                open={!!anchorElement}
                anchorEl={anchorElement}
                onClick={handleClose}
                onClose={handleClose}
            >
                <MenuItem onClick={downloadText}>
                    <ListItemIcon>
                        <NotesIcon />
                    </ListItemIcon>
                    <ListItemText>
                        {t('text')}
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={downloadSRT}>
                    <ListItemIcon>
                        <CodeIcon />
                    </ListItemIcon>
                    <ListItemText>
                        {t('srt')}
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={downloadVTT}>
                    <ListItemIcon>
                        <CodeIcon />
                    </ListItemIcon>
                    <ListItemText>
                        {t('vtt')}
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={downloadJSON}>
                    <ListItemIcon>
                        <CodeIcon />
                    </ListItemIcon>
                    <ListItemText>
                        {t('json')}
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={downloadTSV}>
                    <ListItemIcon>
                        <CodeIcon />
                    </ListItemIcon>
                    <ListItemText>
                        {t('tsv')}
                    </ListItemText>
                </MenuItem> 
                
            </Menu>
        </>
    );
}