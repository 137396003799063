import "@fontsource/roboto/100.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/900.css";

import { createTheme } from "@mui/material/styles";
import MuiThemeProvider from "@mui/material/styles/ThemeProvider";
import CssBaseline from "@mui/material/CssBaseline";
import useThemeStore from "./state/useThemeStore";

const components = {
  MuiDialog: {
    styleOverrides: {
      root: {
        marginLeft: -8,
        marginRight: -8,
      },
    },
  },
};

export const LIGHT_THEME = createTheme({
  typography: {
    fontFamily: "Rudaw, Arial",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
            @font-face {
              font-family: 'Rudaw';
              font-style: normal;
              font-display: swap;
              font-weight: 400;
              src: local('Rudaw'), local('Rudaw-Regular'), url('/fonts/NewRudaw-Regular.woff2') format('woff2');
            }
          `,
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#FF5733",
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#E0C2FF",
      light: "#F5EBFF",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#47008F",
    },
    background: {
      default: "#f3f6fb",
    },
  },
});

export const DARK_THEME = createTheme({
  typography: {
    fontFamily: "Rudaw, Arial",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
            @font-face {
              font-family: 'Rudaw';
              font-style: normal;
              font-display: swap;
              font-weight: 400;
              src: local('Rudaw'), local('Rudaw-Regular'), url('/fonts/NewRudaw-Regular.woff2') format('woff2');
            }
          `,
    },
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#FF5733",
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#E0C2FF",
      light: "#F5EBFF",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#47008F",
    },
  },
});

export default function ThemeProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const theme = useThemeStore((store) => store.theme);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
}
